// src/components/Tokenomics.js
import React from 'react';
import './Tokenomics.css';

const Tokenomics = () => {
  return (
    <div>
      <h1>Tokenomics</h1>
      <div className='section-container'>
        <section className='tokenomics'>
          <div class="hexagon-wrapper">
            <div class="hexagon">
              <i class="fa-solid fa-parachute-box"></i>
            </div>
          </div>
          <div className='sub'>
            <h3>TOTAL Supply</h3>
            <p>1,000,000,000</p>
          </div>
        </section>
        <section className='tokenomics'>
          <div class="hexagon-wrapper">
            <div class="hexagon">
              <i class="fa-solid fa-fire"></i>
            </div>
          </div>
          <div className='sub'>
            <h3>LP Burned</h3>
            <p>100%</p>
          </div>
        </section>
        <section className='tokenomics'>
          <div class="hexagon-wrapper">
            <div class="hexagon">
              <i class="fa-solid fa-scissors"></i>
            </div>
          </div>
          <div className='sub'>
            <h3>Tax</h3>
            <p>0% Buy/Sell</p>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Tokenomics;