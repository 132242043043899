// src/components/Roadmap.js
import React from 'react';
import './Roadmap.css';

const Roadmap = () => {
  return (
    <section className="roadmap-section">

      <h1>UPCOMING REVEAL</h1>

      <div>
        <p>Amplify <span className='highlight-bg'>Marketing</span> for Maximum Exposure. <span className='highlight-bg'>Content Creation & Distribution.</span></p>
        <p>Launch <span className='highlight-bg'>Incentive-Based App for Contributors</span>. The app that rewards contributors based on their level of involvement and contributions. <span className='highlight-bg'>Transparent and fair incentive structure</span> that motivates contributors to participate actively.</p>
        <p>Generate <span className='highlight-bg'>Revenue Stream</span> for Sustainable Growth. Secret reveal at 10,000 holders.</p>
      </div>

    </section>
  );
};

export default Roadmap;