// src/components/Linked.js
import React from 'react';
import './Linked.css';

const Linked = () => {
  return (
    <section id="about" className="about-section">
      <h1>About Smiley The Cat</h1>
      <p><span className='highlight-bg'>$SMILEY</span> is a meme token centered around the adorable, always-happy cat. She loves selfies and <span className='highlight-underline'>spreading joy everywhere she goes.</span></p>
      <a href='https://raydium.io/swap/?outputMint=#&inputMint=sol' rel="noreferrer" target='_blank'><button className="buy-button">Take Some 😻</button></a>
      <div className='linked'>
      <a href='https://x.com/smileyonsolana' rel="noreferrer" target='_blank'><button className="link-button"><i class="fab fa-square-x-twitter"></i></button></a>
      <a href='https://t.me/smileythecat' rel="noreferrer" target='_blank'><button className="link-button"><i class="fab fa-telegram"></i></button></a>
        <button className="link-button"><i class="fa-solid fa-chart-line"></i></button>
      </div>
    </section>
  );
};

export default Linked;