// src/components/Explore.js
import React from 'react';
import './Explore.css';

const Explore = () => {
  return (
    <section className="explore-section">

      <h1>JOIN <span className='highlight-bg'>$SMILEY</span> community and spread joy together</h1>

      <div className='img-list'>
        <div className='container-img'><a className="c-preview">
          <div className="c-preview__img"></div>
        </a></div>
        <div className='container-img'><a className="c-preview">
          <div className="c-preview__img"></div>
        </a></div>
        <div className='container-img'><a className="c-preview">
          <div className="c-preview__img"></div>
        </a></div>
        <div className='container-img'><a className="c-preview">
          <div className="c-preview__img"></div>
        </a></div>
        <div className='container-img'><a className="c-preview">
          <div className="c-preview__img"></div>
        </a></div>
        <div className='container-img'><a className="c-preview">
          <div className="c-preview__img"></div>
        </a></div>
        <div className='container-img'><a className="c-preview">
          <div className="c-preview__img"></div>
        </a></div>
        <div className='container-img'><a className="c-preview">
          <div className="c-preview__img"></div>
        </a></div>
        <div className='container-img'><a className="c-preview">
          <div className="c-preview__img"></div>
        </a></div>
      </div>
    </section>
  );
};

export default Explore;