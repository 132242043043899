// src/components/Tuts.js
import React from 'react';
import smileyCatImage from '../assets/smiley-cat.jpg';
import './Tuts.css';

const Tuts = () => {
  return (
    <section className="tuts-section">
      <div class="wrapper">
      <h1>How to take some joy from <span className='highlight-bg'>$SMILEY</span></h1>
        <ul>
          <li><span className='highlight-box'>1</span><p>Download <a href='https://phantom.app/' target='_blank'>Phantom</a> and create a wallet on Solana blockchain.</p></li>
          <li><span className='highlight-box'>2</span><p>Buy <span className='highlight-bg'>$SOL</span> and send it to your Phantom wallet. You can buy <span className='highlight-bg'>$SOL</span> on <span className='highlight-underline'>Binance</span>, <span className='highlight-underline'>OKX</span>, or <span className='highlight-underline'>Bybit</span>... and then withdraw to your Phantom wallet.</p></li>
          <li><span className='highlight-box'>3</span><p>Buy <span className='highlight-bg'>$SMILEY</span> on <a href='https://raydium.io/swap/?outputMint=#&inputMint=sol' target='_blank'>Raydium</a>. Go to Raydium, connect your Phantom wallet and swap <span className='highlight-bg'>$SMILEY</span> with <span className='highlight-bg'>$SOL</span>. <span className='highlight-bg'>$SMILEY</span> CA: #############################</p></li>
        </ul>
      </div>

    </section>
  );
};

export default Tuts;